// Colors
$primary: #03B6ED;
$secondary: #ADADAD;
$darkblue: #212A4B;

// Buttons
$btn-border-radius: 5px;
$btn-padding-x: 3.5rem;
$btn-padding-y: .5rem;

// Typography
$font-family-base: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
$font-weight-light: 300;
$font-weight-bold: 600;

// Layout
$navbar-nav-link-padding-x: 1rem;
$body-bg: white;
$body-color: #212A4B;
$primary-100: #212a4b0d;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
);

$grid-gutter-width: 2rem;
$container-padding-x: $grid-gutter-width;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 12
);

// Allows to have the negative margin classes like mt-n3
$enable-negative-margins: true;

$border-radius: .6rem;
$border-radius-sm: .25rem;
$border-radius-lg: 1rem;
